import React from 'react';
import { Link } from 'react-router-dom';
// import { Component } from 'react';
import ResponsiveComponent from '../ResponsiveComponent';
import SanitizedHTML from 'react-sanitized-html';
import { globalLangStateContext } from '../../UserContext';

export default class ProjectDetailsBox extends ResponsiveComponent {
    constructor(props) {
        super(props);

    }

    render() {
        if (!this.context.lang) return '';
        let projectDes = 'Loading...';
        let basicDetails = [];
        if (this.props.content) {
            let projectDetails = this.props.content;
            console.log(projectDetails);
            
            basicDetails = Object.assign({}, projectDetails);

            switch (basicDetails.projekt_art) {
                case '1':
                    basicDetails.project_typ = "Verbundforschung";
                    break;
                case '2':
                    basicDetails.project_typ = "Grundlagenforschung";
                    break;
                case '3':
                    basicDetails.project_typ = "Stipendium";
                    break;
                default:
                    basicDetails.project_typ = "No information";
            }
            if (this.context.lang == 'en') {
                delete basicDetails.title;
                projectDes =  projectDetails.description_eng;
            } else {
                delete basicDetails.title_eng;
                projectDes = projectDetails.description;
            }

            delete basicDetails.bild;
            delete basicDetails.projekt_art;
            delete basicDetails.netzwerkordner;

            delete basicDetails.description;
            delete basicDetails.description_eng;
            delete basicDetails.id;
            // console.log(this.state.projectDetails.description);
        }

        return (
            <div className="row">
                <div className="py-2 col-12 col-sm-40 text-left0">
                    {Object.keys(basicDetails).map((key, index) => {
                        let keyText = key;
                        if (this.context.lang == 'en') {
                            switch (key) {
                                case 'project_typ':
                                    keyText = 'Type';
                                    break;
                                case 'title_eng':
                                    keyText = 'Title';
                                    break;
                                case 'timeperiod':
                                    keyText = 'Duration';
                                    break;
                                case 'Sponsor':
                                    keyText = 'Sponsorship';
                                    break;
                                default:
                                    keyText = key;
                            }
                        } else {
                            switch (key) {
                                case 'project_typ':
                                    keyText = 'Projekt Art';
                                    break;
                                case 'title':
                                    keyText = 'Titel';
                                    break;
                                case 'timeperiod':
                                    keyText = 'Laufzeit';
                                    break;
                                case 'Sponsor':
                                    keyText = 'Förderer';
                                    break;
                                default:
                                    keyText = key;
                            }
                        }
                        return (<dl key={index}>
                            <dt className="text-capitalize">{keyText}</dt>
                            <dd><SanitizedHTML html={basicDetails[key]} /></dd>
                        </dl>
                        )
                    })}
                </div>
                <div className="py-2 col-12 col-sm-80">
                    <dl>
                        <dt>Description</dt>
                        <dd><SanitizedHTML html={projectDes} /></dd>
                    </dl>
                </div>
                <div className="py-2 col-12 col-sm-80">
                    <div className='project-img'>
                        <img src={process.env.PUBLIC_URL + '/img/projects/' + this.props.content.bild} alt={this.props.content.bild} />
                    </div>
                </div>
            </div>
        )
    }
}
ProjectDetailsBox.contextType = globalLangStateContext;