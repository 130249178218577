import React from 'react';
// import { Component } from 'react';
import ResponsiveComponent from '../ResponsiveComponent';
import ReactPaginate from 'react-paginate';
import SearchBar from '../Search/SearchBar';
import imeAPICalls from '../../imeAPICalls';
import { globalLangStateContext } from '../../UserContext';
import { Link } from 'react-router-dom';

class ProjectTable extends ResponsiveComponent {
    APICalls = new imeAPICalls();

    constructor(props) {
        super(props);
        this.updateInput = this.updateInput.bind(this);

        this.state = {
            searchInput: '',
            keywords: (this.props.keywords) ? this.props.keywords : '',
            pageNo: 1,
            hasPag: (this.props.pagination == false) ? false : true,
            totalRows: 0,
            totalPages: 1,
            itemsPerPage: 10,
            projects: [],
            data: []
        }
    }

    fetchData = () => {
        // console.log(this.props.keywords);
        this.APICalls.get({ endpoint: 'Research/AllProjects', pageNo: this.state.pageNo, search: this.state.searchInput, keywords: this.state.keywords}).then((res) => {
            this.setState({
                data: res, 
                projects: res.results,
                totalRows: res.pagination.totalRows,
                totalPages: res.pagination.totalPages,
                itemsPerPage: res.pagination.itemsPerPage
            });
            // console.log(this.state.totalPages);
        });
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props !== prevProps) {
          this.fetchData();
        }
      }

    componentDidMount() {
        this.fetchData();
    }

    handlePageClick = (obj) => {
        let selected = Number(obj.selected) + 1;
        this.setState({pageNo: selected}, () => {
            this.fetchData();
        });
    }

    updateInput = async (input) => {
        // console.log(input);
        this.setState({searchInput: input, pageNo: 1}, () => {
            this.fetchData();
        });
    }

    echoProjects(projects) {
        // console.log(projects);
        if (projects == null || projects == undefined || projects == []) return (<tr><td colspan="2">{this.context.webText.project.no_results}</td></tr>);
        let projectsHTML = projects.map(elm => {
            // let pdf = process.env.PUBLIC_URL + '/pdf/projects/' + elm.p_pdf;
            return <tr key={elm.frfp_id}>
            <td>
                <p className="title font-weight-bold0"><Link to={"/research/project/"+elm.frfp_id}>{(this.context.lang == 'ge') ? elm.frfp_title : elm.frfp_title_eng}</Link></p>
            </td>
            <td>
                <p className="tag"><Link to={"/research/"+elm.frdp_id}>{(this.context.lang == 'ge') ? elm.frdp_title : elm.frdp_title_eng}</Link></p>
            </td>
            <td scope="row">{elm.frfp_timeperiod}</td>
        </tr>
        });
        return projectsHTML;
    }

    render() {
        if (!this.context.webText) return '';
        let className = "projects " + this.props.className;
        let tableClass = "table table-sm0 table-striped table-hover";
        // let hasPag = (this.props.pagination == false) ? false : true, totalPages = 1, itemsPerPage = 10;
        let projects = this.state.projects, projectsHTML = 'Loading...';
        // if (this.props.projects) {
        //     this.setState({projects: this.props.projects});
        //     totalPages = this.props.pagination.totalPages; 
        //     itemsPerPage = this.props.pagination.itemsPerPage;
        // } else if (this.state.data.success) {
        //     this.setState({projects: this.state.data.results});
        //     // projectsHTML = this.echoProjects(projects);
        //     hasPag = true; 
        //     totalPages = this.state.data.pagination.totalPages; 
        //     itemsPerPage = this.state.data.pagination.itemsPerPage;
        // }
        if (this.props.limit) {
            // console.log(projects);
            let limit = Number(this.props.limit);
            projects = this.state.projects.slice(0, limit);
        }
        projectsHTML = this.echoProjects(projects);
        // console.log(projects);
        // console.log(this.context.webText);
        let pagiHTML = null;
        if (this.state.hasPag && this.state.totalPages>1) {
            let forcePage = Number(this.state.pageNo) - 1;
            pagiHTML = <ReactPaginate
                forcePage={forcePage}
                pageCount={this.state.totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                breakLabel={'...'}
                breakClassName={'break-me'}
                onPageChange={this.handlePageClick}
                containerClassName={'b-pagi'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
            />
        }
        return(
            <div id={this.props.id} className={className} style={{height: `${this.props.height}`}} >
                <div className="row">
                    <div className="col-12 col-md-8">{pagiHTML}</div>
                    <div className="col-12 col-md-4">
                        {this.props.search &&
                        <SearchBar className="publication-search" input={this.state.searchInput} onChange={this.updateInput}/>
                        }
                    </div>
                    <div className="col-12 mb-3"><p>Results: {this.state.totalRows} Records</p></div>
                </div>
                <div className="table-responsive">
                <table className={tableClass} style={{"textAlign": "left"}}>
                    {this.props.thead === '1' &&
                        <thead className="thead-color1">
                            <tr>
                            <th scope="col" width="300px">{this.context.webText.project.project_title}</th>
                            <th scope="col" width="300px">{this.context.webText.project.research_area}</th>
                            <th scope="col" width="150px">{this.context.webText.project.duration}</th>
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {projectsHTML}
                    </tbody>
                </table>
                </div>
                {pagiHTML}
            </div>
        )
    };
}
ProjectTable.contextType = globalLangStateContext;
export default ProjectTable;