import React, { Component } from 'react';
import { Link, useHistory, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderBanner from '../HeaderBanner';
import NewsSlider from '../News/NewsSlider';
import Box from '../Boxes/Box';
import '../Equipment/equipment.scss';
import Db from '../../control/class.db';
import withLangSwitchListener from '../Languages/LangSwitchListener';
import { globalLangStateContext } from '../../UserContext';
import imeAPICalls from '../../imeAPICalls';

let intro_eng = '<p>IME has a broad variety of modern equipment available. A virtual tour is being created at the moment. To inform yourself on a specific area, you can download our information brochures below. </p>';
let intro = '<p>Das IME verf&uuml;gt &uuml;ber eine umfangreiche und moderne Anlagenausstattung. Ein virtueller Rundgang ist momentan in Arbeit. Um sich &uuml;ber konkrete Ausstattungsbereiche zu informieren, besteht hier die M&ouml;glichkeit, unsere Flyer herunterzuladen.</p>';

const slides = [
    // {
    //     titel_eng: 'Flyer',
    //     text_eng: 'IME has a broad variety of modern equipment available. A virtual tour is being created at the moment. To inform yourself on a specific area, you can download our information brochures here.',
    //     titel: 'Flyer',
    //     text: 'Das IME verf&uuml;gt &uuml;ber eine umfangreiche und moderne Anlagenausstattung. Ein virtueller Rundgang ist momentan in Arbeit. Um sich &uuml;ber konkrete Ausstattungsbereiche zu informieren, besteht hier die M&ouml;glichkeit, unsere Flyer herunterzuladen.',
    //     button: false,
    //     pic: 'ausstattung.png'
    // },
    {
        titel_eng: 'IME (Introduction)',
        text_eng: '',
        titel: 'IME (Introduction)',
        text: '',
        button: false,
        buttonUrl: '/img/equipment/IME_Eng.pdf',
        pic: 'IME_Eng.jpg'
    },
    {
        titel_eng: 'Vacuum Metallurgy',
        text_eng: '',
        titel: 'Vacuum Metallurgy',
        text: '',
        button: false,
        buttonUrl: '/img/equipment/vacuum_flyer.pdf',
        pic: 'vacuum_flyer.jpg'
    },
    {
        titel_eng: 'Recyclingmetallurgy',
        text_eng: '',
        titel: 'Recyclingmetallurgy',
        text: '',
        button: false,
        buttonUrl: '/img/equipment/recyclingmetallurgy.pdf',
        pic: 'recyclingmetallurgy.jpg'
    },
]

let equipment = [
    {
        id: 1,
        title: 'Process Metallurgy',
        description: '',
        button: '',
        buttonUrl: '',
        image: process.env.PUBLIC_URL + '/img/equipment/lbo_abguss_img_id_1091.jpg'
    },
    {
        id: 2,
        title: 'Materials synthesis',
        description: '',
        button: '',
        buttonUrl: '',
        image: process.env.PUBLIC_URL + '/img/equipment/img_2332_id_4632.jpg'
    },
    {
        id: 3,
        title: 'Metal Electrolysis',
        description: '',
        button: '',
        buttonUrl: '',
        image: process.env.PUBLIC_URL + '/img/equipment/temp_id_2530.jpg'
    },
    {
        id: 4,
        title: 'Basics',
        description: '',
        button: '',
        buttonUrl: '',
        image: process.env.PUBLIC_URL + '/img/equipment/mini_dto_id_2018.jpg'
    },
    {
        id: 5,
        title: 'Chemical Analysis',
        description: '',
        button: '',
        buttonUrl: '',
        image: process.env.PUBLIC_URL + '/img/equipment/neu_hd_elektron_id_3705.jpg'
    }
];

class Equipment extends Component {
    APICalls = new imeAPICalls();

    constructor(props) {
        super(props);
        this.state = {
            intro: {},
            // equipment: equipment,
            data: Db.get({action: 'EquipCat'}).then(res => res)
        }
    }    

    componentDidMount() {
        // Db.get({action: 'EquipCat'}).then((res) => {
        //     this.setState({data: res});
        // });
        // this.updateIntro();
        this.APICalls.get({endpoint: 'Equipment/Technology'}).then(res => {
            this.setState({data: res});
        });
        this.APICalls.get({endpoint: 'Texts', meta: 'Equipment'}).then(res => {
            this.setState({intro: res});
        });
    }
    
    // componentDidUpdate(prevProps) {
    //     // Typical usage (don't forget to compare props):
    //     if (this.props.webText !== prevProps.webText) {
    //       this.updateIntro();
    //     }
    // }
    
    // updateIntro() {
    //     if (localStorage.getItem('lang') === 'ge') {
    //         this.setState({intro: intro});
    //     } else {
    //         this.setState({intro: intro_eng});
    //     }
    // }
    
    render() {
        // let boxContent = Array();
        // equipment.forEach((elm, index) => {
        //     boxContent[index] = {
        //         title: elm.title,
        //         image: elm.image,
        //         button: elm.button,
        //         buttonUrl: '/equipment/' + elm.id,
        //         description: elm.description
        //     };
        // });
        let equipmentCat = [];
        let boxContent = [];
        if (this.state.data.success) {
            equipmentCat = this.state.data.results;
            //console.log(equipmentCat);
            equipmentCat.forEach((elm, index) => {
                boxContent[index] = {
                    title_eng: elm.typ_eng,
                    title: elm.typ,
                    image: process.env.PUBLIC_URL + '/img/equipment/' + elm.bild,
                    button: elm.button,
                    buttonUrl: '/equipment/' + elm.id,
                };
            });
        }
        let texts = (this.context) ? this.context.webText : null;
        let intro = '';
        if (this.state.intro.success) {
            intro = this.state.intro.results.filter(x => x.sprache == this.context.language)[0].txt;
        }
        return (texts == null) ? '' : (
            <div className="equipment">
                <HeaderBanner img={process.env.PUBLIC_URL + '/img/home-slider/160224-IME-208.jpg'} transformY='0%' overlay=''/>
                <div className="d-flex justify-content-between container sidebar-right0">
                    {/* <LeftSidebar/> */}
                    <div id="" role="article" className="main-content">
                        {/* <FacultyStage/> */}
                        <div id="wrapper-2-outer0">
                        <div id="wrapper">
                            {/*googleon: all*/}
                            <div className="">
                                <div className="content" role="article">
                                    <div id="intro" className="py-3">
                                        <h2 className="heading">{texts.equipment.flyer}</h2>
                                        <div className="intro-wrap p-4 bg-grey">
                                        <div className="px-2">
                                            <div className="row">
                                                <div className="py-2 col-12 col-sm-7">
                                                    <div className="" dangerouslySetInnerHTML={{__html: intro}} />
                                                    <div className="row">
                                                        <div className="py-3 col-12 col-sm-7">
                                                            <ul>
                                                                {slides.map((elm, index) => {
                                                                    if (index !== -1) {
                                                                        return (
<li key={index}><a href={elm.buttonUrl}>{elm.titel_eng}</a></li>
                                                                        )
                                                                    }
                                                                })}
                                                                
                                                                {/* <li><a href="#"> 	Vacuum Metallurgy</a></li>
                                                                <li><a href="#"> 	Recyclingmetallurgy</a></li> */}
                                                            </ul>
                                                        </div>
                                                        {/* <div className="py-3 col-12 col-sm-5">
                                                            <img src={process.env.PUBLIC_URL + '/img/equipment/ausstattung.png'} alt="IME Team" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="py-2 col-12 col-sm-5">
                                                    <div style={{'margin': '-15px 0'}}>
                                                    <NewsSlider slides={slides} imgFolder={process.env.PUBLIC_URL + '/img/equipment/'} externalUrl="1" className="equipment-slider" height="250px"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div id="topics" className="py-3">
                                        <h2 className="heading">{texts.equipment.title}</h2>
                                        <div className="">
                                            <div className="row">
                                                {/* <div className="col-12 col-lg-4 d-flex">
                                                    <NewsSlider slides={slides} className="equipment-slider" height="400px"/>
                                                </div> */}
                                                {boxContent.map((elm, index) => (
                                                <div key={index} className="col-12 col-lg-4 d-flex">
                                                    <Box content={elm} type="equipment" linkTitle="1" classNames="equipment-cat-box rounded bg-darkblue0"/>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <RightSidebar/> */}
                </div>
            </div>
        );
    }
}
Equipment.contextType = globalLangStateContext;

export default withLangSwitchListener(Equipment);