import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StringHandle from '../../utility/stringHandle';
import withLangSwitchListener from '../Languages/LangSwitchListener';
import imeAPICalls from '../../imeAPICalls';
import { globalLangStateContext } from '../../UserContext';
import { Helmet } from 'react-helmet';

class ProfileDetails extends Component {
    APICalls = new imeAPICalls();

    constructor(props) {
        super(props);
        this.state = {
            group: {},
            topic: {},
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props != prevProps) {
            this.fetchData();
        }
    }

    fetchData() {
        this.APICalls.get({ endpoint: 'Team/GroupTitleFromProfile', id: this.props.id }).then((res) => {
            this.setState({ group: res });
        });
        this.APICalls.get({ endpoint: 'Team/ResearchTopicFromProfile', id: this.props.id }).then((res) => {
            this.setState({ topic: res });
        });
    }
    
    render() {
        if (!this.context.lang) return '';
        let profile = null, groupTitle = '', topicHtml = '';
        let dissertation = '', dissertation_eng = '', duration = '', disse = null;
        let heading = '';
        if (this.props.data.success) {
            profile = this.props.data.results[0];
            // console.log(profile);
            let title = '';
            if (profile.t_titel != 16) title = profile.tt_titel;
            if ((title == 'M.Sc.') || (title == 'B.Sc.')) {
                profile.name = profile.t_vorname + ' ' + profile.t_name + ', ' + title;
            } else {
                profile.name = title + ' ' + profile.t_vorname + ' ' + profile.t_name;
            }
            
            profile.image = process.env.PUBLIC_URL + '/img/team/' + profile.t_bild;

            if (profile.t_dissertation) {
                dissertation = StringHandle.beautifyHTML(profile.t_dissertation, true);
            }
            if (profile.t_dissertation_eng) {
                dissertation_eng = StringHandle.beautifyHTML(profile.t_dissertation_eng, true);
            }
            disse = (this.context.lang == 'ge') ? dissertation : dissertation_eng;
            if (dissertation == '') disse = dissertation_eng;
            if (dissertation_eng == '') disse = dissertation;
            duration = (profile.t_duration) ?? profile.t_duration;

        }
        if (this.state.group.success) {
            let group = this.state.group.results[0];
            // console.log(group);
            groupTitle = (this.context.lang == 'ge') ? group.te_einteilung : group.te_einteilung_eng;
        }
        if (this.state.topic.success) {
            // let topic = this.state.topic.results[0];
            // console.log(group);
            // topicHtml = <Link to={'/research/' + topic.frdp_id} >{(this.context.lang == 'ge') ? topic.frdp_title : topic.frdp_title_eng}</Link>;
            let topics = this.state.topic.results;
            const topicArray = topics.map((topic, index) => {
                if (index == 0) {
                    return (
                    <Link to={'/research/' + topic.frdp_id} >{(this.context.lang == 'ge') ? topic.frdp_title : topic.frdp_title_eng}</Link>
                    )
                } else {
                    return [', ',
                    <Link to={'/research/' + topic.frdp_id} >{(this.context.lang == 'ge') ? topic.frdp_title : topic.frdp_title_eng}</Link>
                    ]
                }
            });
            topicHtml = topicArray;
            // console.log('Topic: ', topicArray);
        }
        if (this.props.data.success && this.state.group.success) {
            if (profile.t_einteilung !== 8) {
                heading = <h2 className="heading"><Link className="d-inline-block " to="/team">{this.context.webText.team.title}</Link> <span className="text-dark">&#187; {StringHandle.capitalize(groupTitle)}</span></h2>;
            } else {
                heading = <h2 className="heading"><Link className="d-inline-block " to="/team">{this.context.webText.team.title}</Link> <span className="text-dark">&#187;</span> <Link className="d-inline-block " to="/alumni">{this.context.webText.team.alumni}</Link></h2>;
            }
        }
        return (!profile) ? '' : (
            <div className="">
                <Helmet>
                    <meta name="description" content={"RWTH IME Profile "+profile.name} />
                    <meta name="keywords" content={"RWTH "+profile.name} />
                </Helmet>
                {heading}
                <div className="intro-wrap p-4 bg-grey text-left">
                    <div className="px-2">
                        {profile &&
                            <div className="row">
                                <div className="py-2 col-12 col-sm-2">
                                    <div className="profile-img">
                                        <img src={profile.image} alt="IME Team" />
                                    </div>
                                </div>
                                <div className="py-2 col-12 col-sm-10">
                                    <h4>{profile.name}</h4>
                                    {(profile.t_einteilung !== 8)
                                        ? <div className="row">
                                            {/* <div className="py-2 col-12 col-sm-4" dangerouslySetInnerHTML={{__html: this.state.intro}} /> */}
                                            <div className="py-2 col-12 col-sm-6">
                                                <dl>
                                                    <dt>Job:</dt>
                                                    <dd>{(this.context.lang == 'ge') ? StringHandle.capitalize(profile.t_beruf) : StringHandle.capitalize(profile.t_beruf_eng)}</dd>
                                                </dl>
                                                <dl>
                                                    <dt>Topic: {topicHtml}</dt>
                                                    <dd dangerouslySetInnerHTML={{ __html: (this.context.lang == 'ge') ? profile.t_gebiet1 : profile.t_gebiet1_eng }} />
                                                </dl>
                                                {profile.t_gebiet2_eng &&
                                                    <dl><dt>Supervision:</dt><dd dangerouslySetInnerHTML={{ __html: (this.context.lang == 'ge') ? profile.t_gebiet2 : profile.t_gebiet2_eng }} /></dl>}

                                            </div>
                                            <div className="py-2 col-12 col-sm-6">
                                                <p><b>Contact:</b></p>
                                                <p>{profile.t_unternehmen1}<br />
                                                    {profile.t_unternehmen2}<br />
                                                    {profile.t_strasse}<br />
                                                    {profile.t_plz}</p>
                                                {/* </div>
                                        <div className="py-2 col-12 col-sm-4"> */}
                                                <table border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '24%' }}>Room:</td>
                                                            <td>{profile.t_raum}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone:</td>
                                                            <td>{profile.t_tel}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fax:</td>
                                                            <td>{profile.t_fax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email: </td>
                                                            <td><a href={"mailto:" + profile.t_mail}>{profile.t_mail}</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : <div className="row">
                                            <div className="py-2 col-12 col-sm-12">
                                                {disse &&
                                                <dl>
                                                    <dt>{this.context.webText.team.phd_topic}:</dt>
                                                    <dd>
                                                        <a
                                                            target='_blank'
                                                            rel="noopener noreferrer"
                                                            href={'//' + profile.t_dissert_url}
                                                            dangerouslySetInnerHTML={{ __html: (this.context.lang == 'ge') ? dissertation : dissertation_eng }} />
                                                    </dd>
                                                </dl>
                                                }
                                                <dl>
                                                    <dt>{this.context.webText.team.time_period}:</dt>
                                                    <dd dangerouslySetInnerHTML={{ __html: duration }} />
                                                </dl>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
ProfileDetails.contextType = globalLangStateContext;
export default withLangSwitchListener(ProfileDetails);